import React from 'react'
import TextAnimation from '../components/TextAnimation'


class LBHome extends React.Component {


  render() {
    return (
      <>
      <section className="home-container">
        <div className="home-content-wrapper">
          <div className="logo-lb">Luca Belli</div>
          <div className="stripes stripe-yellow"></div>
          <div className="stripes stripe-magenta"></div>
          <div className="stripes stripe-blue"></div>
          <div className="home-text">
            <div className="home-text-wrapper">
              <span className="first-fade">Ciao  👋🏼  sono <span className="name">Luca</span>. Mi occupo di...</span> <br/>
              <span className="yellow third-fade">e-commerce</span> <br/>
              <span className="magenta"><TextAnimation /></span><br/>
              <span className="blue fifth-fade">front-end dev</span><br/>
            </div>  
          </div>
        </div>
        <div className="arrow-down">scroll</div>
        <div className="home-bg"></div>
      </section>
      </>
    )
  }
}

export default LBHome
