import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import LBHome from '../components/LBHome'
import LBNavigation from '../components/LBNavigation'
import LBCard from '../components/LBCard'
import SEO from '../components/SEO'
import Particles from "react-tsparticles";

const Index = ({ data }) => {
  const posts = data.allContentfulPost.edges
  const particlesInit = (main) => {
    console.log(main);
  };
  const particlesLoaded = (container) => {
    console.log(container);
  };
  return (
    <div className="bg-index">
      <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        "background": {
          "color": {
            "value": "#292929"
          }
        },
        "fullScreen": {
          "zIndex": 1
        },
        "interactivity": {
          "events": {
            "onClick": {
              "enable": true,
              "mode": "repulse"
            },
            "onHover": {
              "enable": true,
              "mode": "bubble"
            }
          },
          "modes": {
            "bubble": {
              "distance": 250,
              "duration": 2,
              "opacity": 0,
              "size": 0
            },
            "grab": {
              "distance": 400
            },
            "repulse": {
              "distance": 400
            }
          }
        },
        "particles": {
          "color": {
            "value": "#ffffff"
          },
          "links": {
            "color": {
              "value": "#ffffff"
            },
            "distance": 150,
            "opacity": 0.4
          },
          "move": {
            "attract": {
              "rotate": {
                "x": 600,
                "y": 600
              }
            },
            "enable": true,
            "path": {},
            "outModes": {
              "bottom": "out",
              "left": "out",
              "right": "out",
              "top": "out"
            },
            "random": true,
            "speed": 1,
            "spin": {}
          },
          "number": {
            "density": {
              "enable": true
            },
            "value": 40
          },
          "opacity": {
            "random": {
              "enable": true
            },
            "value": {
              "min": 0,
              "max": 1
            },
            "animation": {
              "enable": true,
              "speed": 1,
              "minimumValue": 0
            }
          },
          "size": {
            "random": {
              "enable": true
            },
            "value": {
              "min": 1,
              "max": 3
            },
            "animation": {
              "speed": 4,
              "minimumValue": 0.3
            }
          }
        }
      }}
    />
      <LBHome />
      <LBNavigation isHome={true}/>
      <Layout>
        <SEO title="Home page" description="Home page - Luca Belli personal website | UX designer | Developer" />
        <h1 className="title-last-article">Ultimi articoli</h1>
        <div className="last-articles-wrapper row no-gutters justify-content-center">
          {posts.map(({ node: post}, index) => (
            <LBCard key={"card" + post.id} post={post} page="home" articleNumber={index+1} basePath="/"/>
          ))}
        </div>
        <div className="tutti-gli-articoli-cta">
          <Link to="/articoli/lista" className="button-primary">tutti gli articoli</Link>
        </div>
        <div className="ultimi-articoli-bg"></div>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "DD MMMM YYYY", locale: "it")
          tags {
            id
            title
            slug
          }
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            ogimg: resize(width: 1800) {
              src
            }
          }
          body {
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 160)
            }
          }
        }
      }
    }
  }
`

export default Index
