import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

class LBCard extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      articleClass : "lbcard card-home col-md-5"
    }
  }

  componentDidMount(){
    this.manageArticleClass();
  }

  manageArticleClass() {
    // case: home - total 3 articles - 1st is main for tablet 
    if (this.props.page === "home" && this.props.articleNumber === 1) {
      this.setState({
        articleClass: "lbcard card-home main-article-md col-md-11"
      })
    }
  }

  render() {

    const post = this.props.post
    //console.log(post)
    return (
        <article className={this.state.articleClass}>
          <div className="lbcard_image-wrapper">
            <div className="lbcard_title-wrapper">
              <h2 className="lbcard_title">{post.title}</h2>
            </div>
            <Link key={post.slug} to={`/articoli/${post.slug}/`} className="lbcard_image-link">
              <Img className="lbcard_image"
                fluid={post.heroImage.fluid}
                alt=""
              />
            </Link>
          </div>
          <div key={"wrapper"+post.slug} className="lbcard_content-wrapper">
            <div key={"cat"+post.slug} className="lbcard_category">
            {post.tags.map((tag, index) => (
              <span key={tag.slug}>
                {(index ? '' : '')}
                <Link key={tag.slug} to={`/tag/${tag.slug}/`} className='lb-tag'>{tag.title}</Link>
              </span>
            ))}
            </div>
            <div className="lbcard_date">
              <span className="lbcard_date-wrapper">{post.publishDate}</span>
              <span className="lbcard_duration-wrapper"> {post.body.childMarkdownRemark.timeToRead}' di lettura</span>
            </div>
            <div 
              className="lbcard_body"
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.excerpt,
              }}>
            </div>
            <Link to={`/articoli/${post.slug}/`} key={post.slug} className="lb_card-read-more">
              leggi tutto
            </Link>
          </div>
        </article>
    )
  }
}

export default LBCard
